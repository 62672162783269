import * as React from 'react';
import { Helmet } from "react-helmet"

export interface MarbleHelmetProps {
    pageName: string;
    ogImage: string;
    ogTitle: string;
    ogURL: string;
}

export const MarbleHelmet = (props: MarbleHelmetProps) => {
    const { pageName, ogImage, ogTitle, ogURL } = props;

    return (
        <Helmet htmlAttributes={{lang: 'en'}}>
            <meta charSet="utf-8"/>
            <title>{pageName}</title>


            {/* Open Graph metadata - see https://ogp.me/ */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={ogURL} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:alt" content="Screenshot of the Marble hiring platform" />
            <meta property="og:description" content="The hiring tool of the future - absolutely free. Marble manages your hiring process, so you don't have to." />
            <meta property="og:site_name" content="Marble" />
            <meta property="og:locale" content="en_GB" />
        </Helmet>
    )
}